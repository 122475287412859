import { useEffect, useState } from "react";
import styled from "styled-components";
import GallerySection from "../components/GallerySection";
import Image2 from "../images/alyonaicon.jpeg";
import Rupee from "../images/rupee.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DialpadIcon from "@mui/icons-material/Dialpad";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import AppShortcutSharpIcon from "@mui/icons-material/AppShortcutSharp";
import SingleContact from "./SingleContact";
import Slider from "react-slick";

const ContactopLayout = styled.section``;
const GallerySettings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 10,
  accessibility: false,
  arrows: false,
};

function ContactopSection() {
  return (
    <ContactopLayout>
      <Slider {...GallerySettings}>
        <div>
          <SingleContact Icon={WhatsAppIcon} color="green" heading="WhatsApp" />
        </div>
        <div>
          <SingleContact Icon={FacebookIcon} color="blue" heading="Facebook" />
        </div>
        <div>
          <SingleContact Icon={DialpadIcon} color="black" heading="Phone" />
        </div>
        <div>
          <SingleContact Icon={EmailSharpIcon} color="red" heading="Gmail" />
        </div>
        <div>
          <SingleContact
            Icon={AppShortcutSharpIcon}
            color="green"
            heading="App"
          />
        </div>
      </Slider>
    </ContactopLayout>
  );
}

export default ContactopSection;
