import { useEffect, useState } from "react";
import styled from "styled-components";
import GallerySection from "../components/GallerySection";
import Image2 from "../images/alyonaicon.jpeg";
import Rupee from "../images/rupee.png";
import { Card, Typography } from "@mui/material";

const SingleContactLayout = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
`;

function SingleContact(props) {
  const { Icon, heading, color } = props;
  return (
    <Card
      sx={{
        width: "100px",
        marginTop: "15px",
        marginBottom: "15px",
        marginRight: "20px",
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SingleContactLayout>
        <Icon
          sx={{
            width: "40px",
            height: "40px",
            color: { color },
          }}
        />
        <Typography
          sx={{ fontSize: "15px", fontWeight: "600", color: { color } }}
        >
          {heading}
        </Typography>
      </SingleContactLayout>
    </Card>
  );
}

export default SingleContact;
