import styled from "styled-components";
import GallerySection from "../components/GallerySection";
import Rupee from "../images/rupee.png";
import ContactIcon from "../images/contactus.png";
import DoubtIcon from "../images/doubt.png";
import AlyonaIcon from "../images/alyonatrans.png";
import NotFoundIcon from "../images/notfoundimage.svg";
import { Button, Divider, Typography } from "@mui/material";
import staticants from "../utils/Constants";
import { Link } from "react-router-dom";

const NotFoundLayout = styled.section`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 20px;
`;

function NotFound(props) {
  return (
    <NotFoundLayout>
      <Typography sx={{ fontSize: "16px", fontWeight: "260", color: "black" }}>
        Looks Like You Got Lost
      </Typography>
      <Typography sx={{ fontSize: "28px", fontWeight: "700", color: "black" }}>
        PAGE NOT FOUND!
      </Typography>
      <img width="400px" height="400px" src={NotFoundIcon} alt="" />

      <Link
        style={{
          textDecoration: "none",
          color: "inherit",
          width: "350px",
          display: "flex",
          justifyContent: "center",
        }}
        to="/"
      >
        <Button
          sx={{
            marginLeft: "30px",
            marginRight: "30px",
            backgroundColor: "green",
          }}
          fullWidth
          variant="contained"
        >
          GO HOME!
        </Button>
      </Link>
    </NotFoundLayout>
  );
}

export default NotFound;
