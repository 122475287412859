import { Avatar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";

const HeadingLayout = styled.section`
  /* background-color: yellow; */
  display: flex;
  flex-direction: row;
  align-items: center;
`;

function HeadingElement(props) {
  return (
    <HeadingLayout>
      <Typography
        sx={{
          fontSize: props.size,
          fontWeight: props.weight,
          lineHeight: "1.2",
        }}
      >
        {props.heading}
      </Typography>
      {props.icon && (
        <Avatar
          sx={{ marginLeft: "10px", width: "30px", height: "30px" }}
          src={props.icon}
        />
      )}
    </HeadingLayout>
  );
}

export default HeadingElement;
