import { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CityJbd from "../images/jehanabadjn.jpg";
import CityPatna from "../images/patnajn.webp";
import CityGaya from "../images/gayajn.jpg";
import CityRaj from "../images/rajgir.jpg";
import Image1 from "../images/adv4.jpeg";
import Image2 from "../images/adv5.jpeg";
import Image3 from "../images/adv6.jpeg";
import SingleCity from "./SingleCity";

const CitiesLayout = styled.section``;

const GallerySettings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 50,
  accessibility: false,
  arrows: false,
};

function CitiesSection() {
  return (
    <CitiesLayout>
      <Slider {...GallerySettings}>
        <div>
          <SingleCity img={Image1} cty="Gaya" />
        </div>

        <div>
          <SingleCity img={Image2} />
        </div>

        <div>
          <SingleCity img={Image3} />
        </div>

        <div>
          <SingleCity img={Image1} />
        </div>
      </Slider>
    </CitiesLayout>
  );
}

export default CitiesSection;
