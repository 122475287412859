import styled from "styled-components";
import GallerySection from "../components/GallerySection";
import Rupee from "../images/rupee.png";
import ContactIcon from "../images/contactus.png";
import DoubtIcon from "../images/doubt.png";
import LoveIcon from "../images/love.png";
import HeadingElement from "../components/HeadingElement";
import ContentElement from "../components/ContentElement";
import staticants from "../utils/Constants";
import AppIcon from "../images/app.png";
import AddressIcon from "../images/address.png";
import { Divider } from "@mui/material";
import ReviewsSection from "../components/ReviewsSection";
import ServiceSection from "../components/ServicsSection";
import ChooseusSection from "../components/ChooseusSection";
import CitiesSection from "../components/CitiesSection";
import DownloadappSection from "../components/DownloadappSection";
import ContactusSection from "../components/ContactusSection";
import AddressSection from "../components/AddressSection";
import ContactopSection from "../components/ContactopSection";
import FooterSection from "../components/FooterSection";
import NavMenu from "../components/NavMenu";
import { HashScroll } from "react-hash-scroll";

const HomeParentLayout = styled.section`
  background-color: white;
  margin-top: 0px;
`;

const MarginElement = styled.div`
  margin: ${(props) => props.value};
`;

const MarginLayout = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 30px;
`;

function HomePage() {
  return (
    <HomeParentLayout>
      <NavMenu />
      <br />
      <br />
      <br />

      <MarginLayout>
        <HeadingElement heading="Welcome to" size="28px" weight="400" />
        <HeadingElement
          heading="Alyona MicroFinance®"
          size="28px"
          weight="750"
        />
        <MarginElement value="10px" />
        <ContentElement content={staticants.homeIntro1} size="14px" />
        <HashScroll hash="#hash-home">
          <div></div>
        </HashScroll>

        {/* Gallery section */}
        <MarginElement value="70px" />
        <Divider />
        <GallerySection />
        <HashScroll hash="#hash-gallery">
          <div></div>
        </HashScroll>

        {/* Why choose us section */}
        <Divider />
        <MarginElement value="45px" />
        <HeadingElement
          heading="Why Choose Us"
          size="20px"
          weight="450"
          icon={Rupee}
        />
        <Divider />
        <MarginElement value="25px" />
        <ChooseusSection />
        <HashScroll hash="#hash-chooseus">
          <div></div>
        </HashScroll>

        {/* Services we offer section */}
        <HashScroll hash="#hash-services">
          <div></div>
        </HashScroll>
        <Divider />
        <MarginElement value="50px" />
        <HeadingElement
          heading="Services We Offer"
          size="20px"
          weight="450"
          icon={DoubtIcon}
        />
        <Divider />
        <MarginElement value="25px" />
        <ServiceSection />

        {/* Reviews section */}
        <Divider />
        <MarginElement value="25px" />
        <HeadingElement
          heading="Customers Love Us"
          size="20px"
          weight="450"
          icon={LoveIcon}
        />
        <Divider />
        <MarginElement value="25px" />
        <ReviewsSection />
        <HashScroll hash="#hash-reviews">
          <div></div>
        </HashScroll>

        {/* Cities section */}
        <Divider />
        <MarginElement value="25px" />
        <HeadingElement
          heading="Inside Alyona Finance"
          size="20px"
          weight="450"
          icon={Rupee}
        />
        <Divider />
        <MarginElement value="25px" />
        <CitiesSection />
        <HashScroll hash="#hash-cities">
          <div></div>
        </HashScroll>

        {/* Download app section */}
        <Divider />
        <MarginElement value="25px" />
        <HeadingElement
          heading="AlyonaFinance® Mobile App"
          size="20px"
          weight="450"
          icon={AppIcon}
        />
        <Divider />
        <MarginElement value="25px" />
        <DownloadappSection />
        <HashScroll hash="#hash-download-app">
          <div></div>
        </HashScroll>

        {/* Contact us section */}
        <Divider />
        <MarginElement value="35px" />
        <HeadingElement
          heading="Contact AlyonaFinance®"
          size="20px"
          weight="450"
          icon={ContactIcon}
        />
        <Divider />
        <MarginElement value="25px" />
        <ContactusSection to="amit286udinfo@gmail.com" />
        <HashScroll hash="#hash-contactus">
          <div></div>
        </HashScroll>

        {/* Our Location section */}
        <Divider />
        <MarginElement value="35px" />
        <HeadingElement
          id="we-location"
          heading="We Are Located At"
          size="20px"
          weight="450"
          icon={AddressIcon}
        />
        <Divider />
        <MarginElement value="25px" />
        <AddressSection />
        <HashScroll hash="#hash-location">
          <div></div>
        </HashScroll>


        {/* Our ContactOptions section */}
        <Divider />
        <MarginElement value="35px" />

        <HeadingElement
          heading="Contact Channels"
          size="20px"
          weight="450"
          icon={ContactIcon}
        />
        <Divider />
        <MarginElement value="25px" />
        <ContactopSection />
      </MarginLayout>

      {/*Footer section */}
      <MarginElement value="35px" />
      <MarginElement value="25px" />
      <FooterSection />
    </HomeParentLayout>
  );
}

export default HomePage;
