import { useEffect, useState } from "react";
import styled from "styled-components";
import GallerySection from "../components/GallerySection";
import Image2 from "../images/alyonaicon.jpeg";
import Rupee from "../images/rupee.png";
import { Card } from "@mui/material";
import Address2Icon from "../images/address2.png";
import ServiceTile from "./ServiceTile";

const AddressSectionLayout = styled.section``;

function AddressSection() {
  return (
    <AddressSectionLayout>
      <ServiceTile
        icon={Address2Icon}
        mt="Express Tower, Chennai, Tamilnadu"
        st="55, Adithanar Rd, Pudupet, Komaleeswaranpet, Egmore, Chennai, Tamil Nadu, 600002"
      />
    </AddressSectionLayout>
  );
}

export default AddressSection;
