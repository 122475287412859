import { useState } from "react";
import styled from "styled-components";
import { Button, Card, TextField } from "@mui/material";
import { Link } from "react-router-dom";

const ContactLayout = styled.section`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function ContactusSection(props) {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  const openGmailApp = () => {
    if (name === "" || mobile === "" || message === "") {
      return;
    }
    const gmailUri = `https://mail.google.com/mail/?view=cm&to=${props.to}&subject=Alyona Finance Website Support Message&body=${message}`;
    window.open(gmailUri, "_blank");
  };

  

  const GmailLink = () => {
    const gmailUri = `https://mail.google.com/mail/?view=cm&to=recipient@example.com&subject=My Subject&body=My Message&android_intent=Intent;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;scheme=gmail-mailto://;data=mailto:recipient@example.com?subject=My Subject&body=My Message;end;`;
    return <Link to={gmailUri}>Open Gmail App</Link>;
  };

  function openGmailApp2(to, subject, body) {
    const gmailIntent = `Intent;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;scheme=gmail-mailto://;data=mailto:${to}?subject=${subject}&body=${body};end;`;
    window.open(gmailIntent, "_blank");
  }

  return (
    <Card
      elevation={6}
      sx={{
        borderRadius: "8px",
      }}
    >
      <ContactLayout>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Enter Name"
          type="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
        />
        <TextField
          fullWidth
          sx={{ marginTop: "7px", marginBottom: "7px" }}
          id="outlined-basic"
          label="Mobile Number"
          value={mobile}
          type="number"
          onChange={(e) => setMobile(e.target.value)}
          variant="outlined"
        />
        <TextField
          fullWidth
          multiline="true"
          sx={{ maxHeight: "100px" }}
          id="outlined-basic"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          label="Message"
          variant="outlined"
        />

        <Button
          sx={{ marginTop: "15px", marginBottom: "10px" }}
          fullWidth
          variant="outlined"
        >
          Send Message
        </Button>
        <Button fullWidth variant="contained">
          Email Query
        </Button>
      </ContactLayout>
    </Card>
  );
}

export default ContactusSection;
