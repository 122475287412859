import { useEffect, useState } from "react";
import styled from "styled-components";
import GallerySection from "../components/GallerySection";
import Image2 from "../images/alyonaicon.jpeg";
import { Card, Typography } from "@mui/material";

const SingleCityLayout = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  object-fit: cover;
  width: 400px;
  height: 260px;
`;

function SingleCity(props) {
  return (
    <Card
      elevation={10}
      sx={{
        height: "260px",
        marginTop: "15px",
        marginBottom: "15px",
        marginRight: "20px",
      }}
    >
      <SingleCityLayout>
        <Image src={props.img} alt="" />
        {/* <Typography sx={{ fontSize: "26px", fontWeight: "700" }}>
          {props.cty}
        </Typography> */}
      </SingleCityLayout>
    </Card>
  );
}

export default SingleCity;
