import { useEffect, useState } from "react";
import styled from "styled-components";
import GallerySection from "../components/GallerySection";
import Image2 from "../images/alyonaicon.jpeg";
import AppstoreIcon from "../images/appstore2.png";
import PlaystoreIcon from "../images/playstore.png";
import Rupee from "../images/rupee.png";
import { Card } from "@mui/material";

const DownloadAppLayout = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
`;

const Image = styled.img`
  object-fit: cover;
  width: 200px;
  height: 50px;
`;

const openLink = () => {
  window.open(
    "https://play.google.com/store/apps/details?id=com.amf.alyonamicrofinance",
    "_blank"
  );
};

function DownloadappSection(props) {
  return (
    <Card>
      <DownloadAppLayout>
        <Image src={AppstoreIcon} alt="" onClick={openLink} />
        <Image src={PlaystoreIcon} alt="" onClick={openLink} />
      </DownloadAppLayout>
    </Card>
  );
}

export default DownloadappSection;
