import { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Image1 from "../images/alyonaicon.jpeg";
import Image2 from "../images/adv2.jpeg";
import Image3 from "../images/credit.jpg";
import Image4 from "../images/contract.jpg";
import Image5 from "../images/adv4.jpeg";
import Image6 from "../images/adv5.jpeg";
import Image7 from "../images/adv6.jpeg";
import SliderSingleImage from "./SliderSingleImage";

const GalleryLayout = styled.section``;

const GallerySettings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 50,
  accessibility: false,
  arrows: false,
};

function GallerySection() {
  return (
    <GalleryLayout>
      <Slider {...GallerySettings}>
        <div>
          <SliderSingleImage src={Image3} />
        </div>
        <div>
          <SliderSingleImage src={Image2} />
        </div>
        <div>
          <SliderSingleImage src={Image1} />
        </div>
        <div>
          <SliderSingleImage src={Image5} />
        </div>
        <div>
          <SliderSingleImage src={Image6} />
        </div>
        <div>
          <SliderSingleImage src={Image7} />
        </div>
        <div>
          <SliderSingleImage src={Image4} />
        </div>
        <div>
          <SliderSingleImage src={Image3} />
        </div>
      </Slider>
    </GalleryLayout>
  );
}

export default GallerySection;
