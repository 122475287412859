/* eslint-disable react/style-prop-object */
import { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Image1 from "../images/credit.jpg";

const ImageLayout = styled.section`
  /* background-color: yellow; */
`;

const Image = styled.img`
  object-fit: cover;
  width: 400px;
  height: 200px;
`;

function SliderSingleImage(props) {
  return (
    <div>
      <Card
        elevation="10"
        sx={{
          maxHeight: "200px",
          // width: "400px",
          height: "200px",
          marginTop: "15px",
          marginBottom: "15px",
          marginRight: "20px",
        }}
      >
        <Image src={props.src} alt="" />
      </Card>
    </div>
  );
}

export default SliderSingleImage;
