import { useEffect, useState } from "react";
import styled from "styled-components";
import GallerySection from "../components/GallerySection";
import Image2 from "../images/alyonaicon.jpeg";
import AlyonaIcon from "../images/alyonatrans.png";
import Rupee from "../images/rupee.png";
import AppstoreIcon from "../images/appstore2.png";
import PlaystoreIcon from "../images/playstore.png";
import staticants from "../utils/Constants";
import { Divider, Typography } from "@mui/material";

const FooterLayout = styled.section`
  background-color: black;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
`;

const DownloadAppLayout = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
`;

const Image = styled.img`
  object-fit: cover;
  width: 150px;
  height: 50px;
`;

const openLink = () => {
  window.open(
    "https://play.google.com/store/apps/details?id=com.amf.alyonamicrofinance",
    "_blank"
  );
};

function FooterSection() {
  return (
    <FooterLayout>
      <img width="100px" height="100px" src={AlyonaIcon} alt="" />
      <Typography sx={{ fontSize: "16px", fontWeight: "700", color: "white" }}>
        © AlyonaFinance® NBFC
      </Typography>
      <Divider sx={{ color: "white" }} />
      <Typography
        sx={{
          fontSize: "12px",
          color: "white",
          textAlign: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        {staticants.note}
      </Typography>

      <DownloadAppLayout>
        <Image src={AppstoreIcon} alt="" onClick={openLink} />
        <Image src={PlaystoreIcon} alt="" onClick={openLink} />
      </DownloadAppLayout>

      <Typography sx={{ fontSize: "13px", fontWeight: "700", color: "white" }}>
        © AlyonaFinance 2023-2025
      </Typography>
      <Typography sx={{ fontSize: "13px", fontWeight: "700", color: "white" }}>
        Powered By Evokeup Technologies
      </Typography>
    </FooterLayout>
  );
}

export default FooterSection;
