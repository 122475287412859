import { Avatar, Card, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";

const TileParentLayout = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 10px;
`;

const TileInnerLayout = styled.section`
  display: flex;
  flex-direction: column;
`;

const MarginElement = styled.div`
  margin: ${(props) => props.value};
`;

function ServiceTile(props) {
  return (
    <div>
      <Card
        elevation={15}
        sx={{
          borderRadius: "10px",
          height: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          marginTop: "25px",
          marginBottom: "25px",
        }}
      >
        <TileParentLayout>
          <Avatar
            sx={{ width: "50px", height: "50px", marginRight: "15px" }}
            src={props.icon}
          />
          <TileInnerLayout>
            <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
              {props.mt}
            </Typography>
            <Typography sx={{ fontSize: "13px", color: "black" }}>
              {props.st}
            </Typography>
          </TileInnerLayout>
        </TileParentLayout>
      </Card>
    </div>
  );
}

export default ServiceTile;
