import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";

const ContentLayout = styled.section`
  /* background-color: yellow; */
`;

function ContentElement(props) {
  return (
    <ContentLayout>
      <Typography sx={{ fontSize: props.size }}>
        {props.content}
      </Typography>
    </ContentLayout>
  );
}

export default ContentElement;
