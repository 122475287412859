import styled from "styled-components";
import { Divider, Typography } from "@mui/material";
import staticants from "../utils/Constants";

const TermsLayout = styled.section`
  padding: 20px;
`;

function PrivacyPage() {
  return (
    <TermsLayout>
      <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
        Privacy Policy Alyona Finance
      </Typography>
      <Divider />
      <Typography
        variant="body1"
        component="span"
        sx={{
          fontSize: "13px",
          whiteSpace: "pre-line",
          color: "black",
          marginTop: "15px",
        }}
      >
        {staticants.privacytext}
      </Typography>
    </TermsLayout>
  );
}

export default PrivacyPage;
