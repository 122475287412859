class staticants {
  static getNameInitials = (fullname) => {
    return (
      fullname.substring(0, 1) +
      fullname
        .substring(fullname.indexOf(" ") + 1, fullname.indexOf(" ") + 2)
        .toUpperCase()
    );
  };

  static review3 =
    "Alyona MicroFinance is a great option for anyone who needs a loan. The application process is simple and straightforward, and the approval time is fast. The interest rates are also very competitive. I would definitely recommend Alyona MicroFinance to anyone who is looking for a loan.";
  static review2 =
    "I was very impressed with Alyona MicroFinance. The interest rates were very competitive, and the terms of the loan were very flexible. I was able to get the loan I needed to cover my unexpected medical expenses, and I was able to pay it back on my own terms. I would definitely recommend Alyona MicroFinance to anyone in need of a loan.";
  static review1 =
    "Alyona MicroFinance was a lifesaver when I needed a loan to start my own business. The application process was quick and easy, and I was approved for a loan in no time. The customer service was excellent, and they were always there to answer my questions. I would highly recommend Alyona MicroFinance to anyone in need of a loan.";
  static note =
    "We don't offer any refund for loan processing amounts and don't store and share your personal information with anyone or third-party services. Please note that our privacy policy, terms and conditions, and non-refundable processing fees are on our website. At Alyona Finance, we assume you are already aware of the concepts mentioned. ";
  static serviceText1 =
    "We provide very quick disbursal and approval of Loan. We provide very quick disbursal and approval of Loan";
  static homeIntro1 =
    "Alyona MicroFinance is a leading provider of loan and lending services to individuals and small businesses. We offer a variety of loan products to meet the needs of our customers, and we are committed to providing excellent customer service.";
  static homeIntro =
    "Click on Login button to explore online demographics update service, Aadhaar PVC card ordering & tracking, and more value-added services offered by UIDAI. Your mobile number is required to be registered with the Aadhaar to login.";

  static exampleOne = "Explain quantum computing in simple terms";
  static exampleTwo = "Got any creative ideas for a 10 year old’s birthday?";
  static exampleThree = "How do I make an HTTP request in Javascript?";
  static capOne = "Remembers what user said earlier in the conversation";
  static capTwo = "Allows user to provide follow-up corrections";
  static capThree = "Trained to decline inappropriate requests";
  static limitOne = "May occasionally generate incorrect information";
  static limitTwo =
    "May occasionally produce harmful instructions or biased content";
  static limitThree = "Limited knowledge of world and events after 2021";

  static linkText = "ChatGPT Mar 23 Version";
  static infoText =
    "Free Research Preview. ChatGPT may produce inaccurate information about people, places, or facts";
  static dummyLontText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
      porttitor auctor libero, in faucibus arcu. Aenean gravida dignissim
      ultrices. Donec ac est eu leo bibendum elementum. Vivamus vehicula nulla
      non odio tincidunt, id suscipit nibh bibendum. Morbi gravida ante eu odio
      vestibulum, nec interdum dolor tristique. In hac habitasse platea dictumst.
      Nam id libero sit amet nunc congue venenatis. Maecenas tincidunt magna
      quis metus convallis, eu tincidunt nulla pharetra. Nullam maximus, nisl
      eget sagittis placerat, neque lectus varius tortor, non bibendum dui odio
      auctor justo. Fusce rutrum elit eu quam tincidunt, at dictum magna iaculis.`;

  static privacytext = `
  
Alyona Finance Privacy Policy Agreement

Alyona Finance is committed to protecting the privacy of our customers. This Privacy Policy Agreement explains what information we collect, how we use it, and with whom we share it.

Information We Collect

We collect the following information from our customers:

Name
Contact information (email address, phone number, mailing address)
Government-issued ID (Aadhar card, Pan Card)
Income and employment information
Credit history
Loan information
How We Use Your Information

We use your information to:

Process your loan application
Provide you with loan servicing and support
Conduct credit checks
Manage our risk
Comply with applicable laws and regulations
With Whom We Share Your Information

We may share your information with the following third parties:

Credit reporting agencies
Loan servicers
Fraud prevention companies
Government agencies
Security of Your Information

We take reasonable steps to protect your information from unauthorized access, use, or disclosure. We use a variety of security measures, including encryption, physical security, and access controls.

Non-Refundable Loan Processing Fee

We charge a non-refundable loan processing fee to cover the costs of processing your loan application. This fee is not refundable, even if your loan application is not approved.

Your Choices

You have the right to access, correct, or delete your information. You also have the right to opt out of receiving marketing communications from us. To exercise any of these rights, please contact us at [email protected]

Changes to This Privacy Policy

We may update this Privacy Policy Agreement from time to time. If we make any changes, we will post the updated policy on our website.

Contact Us

If you have any questions about this Privacy Policy Agreement, please contact us at [email protected]`;

  static termstext = `
  Personal Loan Rules, Terms & Conditions that You Should Know!

1. Credibility of the Lender

There are several financial institutions offering personal loans at attractive rates. Some make tempting offers and make people commit instantly. It is very convenient to commit through your phone. But you need to be extremely careful about who exactly is offering to lend to you, and whether they are genuine representatives of good lending institutions or fraudsters involved in personal loan scams.

2. Be certain about the Exact Interest Rate

Interest rate is always the luring factor when it comes to personal loans. However, the flat rate that is offered to you could be misleading. This is because there could be hidden charges in the offering, which will increase your rate. At the same time, if you calculate your interest on reducing balance, you will find out that your actual interest rate is lower than the rate stated on paper. You can get complete clarity on your personal loan EMI by calculating it yourself using our free Personal Loan EMI calculator.

3. Prepayment Details

Personal loans charge prepayment penalties also known as foreclosure charges. This is a fee charged by lenders if you pay off your loan early. You need to understand the charges in-depth and choose a lender with low foreclosure charges if you are planning to use this option to pay off your loan early, or expect to be able to pay off the entire amount after a certain event transpires. Prepayment is a healthy step towards you being free of a loan sooner than your expected date - however, please be aware that most lenders do not offer this facility until a minimum number of EMIs have been paid.

4. Hidden Charges

Personal loans come with additional charges over and above the interest rate quoted to you. You have to usually pay a processing fee but there could be other charges like insurance charges, service fees, etc. It is also important to check the rate of the processing fee. Most lenders deduct the processing fee from the loan amount and transfer the balance to your bank account after your loan gets approved. Additionally, the final monthly EMI you need to pay will also contain extra charges such as insurance, service charges, etc. You can compare the monthly EMI on your loan agreement Vs the monthly EMI obtained from the personal loan calculator to understand the extra charges being levied. Please also note that most lenders levy penalty charges in case of an EMI bounce, or if you cancel the loan after it has been approved.

5. Uses of the Loan

It is very crucial to check if the loan agreement specifies what you can and cannot use the personal loan for. The personal loan rules document will state this. Typically, it should state that you can use it for any legal purpose without any restriction. However, in case it specifies that it cannot be used in certain events, then you should know it before signing.

6. Say no to Faulty or Complicated EMI Schemes

There are some attractive-to-the-eye zero EMI schemes. These are very popular among consumers as they feel they do not need to pay any interest for the product they purchase. This is a very cleverly designed scheme that has confused many borrowers. Potential consumers do not realize that they are being tricked into what appears as an interest-free loan to them. In such products, there are high processing fees, administrative charges, flat fees, etc. Please go through the loan agreement with a fine-tooth comb, and ensure that you thoroughly understand the terms and conditions before signing the agreement.

7. Personal Loan Documents

A lender would require KYC (Know your customer) documents such as driving license, Voter ID, Aadhar Card, etc. Apart from this, salary slips and bank statements would also be required. Therefore, one must keep these personal loan documents ready while applying for a personal loan.
`;

  static dummyAnswer = `Algorithmic thinking can be beneficial in day-to-day life in various ways, such as:

Problem-solving: Algorithmic thinking involves breaking down a problem into smaller components and creating a step-by-step plan to solve it. This approach can be applied to many situations in daily life, such as planning a trip, organizing a project, or solving a household issue.

Decision-making: By using algorithms to weigh different options and outcomes, algorithmic thinking can help with decision-making. It allows for a more systematic and data-driven approach, which can lead to better decisions.

Time management: When you approach tasks with an algorithmic mindset, you can prioritize and schedule tasks more efficiently. You can also break down larger tasks into smaller, more manageable components and prioritize them accordingly.

Regarding your second question, writing algorithms does not necessarily make someone smarter. It is a specific skill set that can be learned and honed through practice and experience. However, people who are skilled in algorithmic thinking and programming may be able to solve certain problems more efficiently and effectively than those who are not. It ultimately depends on the specific situation and the individual's abilities and resources.`;
}

export default staticants;
