import "../src/css/App.css";
import styled from "styled-components";
import { createTheme } from "@material-ui/core/styles";
import MontserratBold from "../src/fonts/Montserrat-Bold.ttf";
import HomePage from "./pages/HomePage";
import { Route, Routes } from "react-router";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";
import { ToastContainer } from "react-toastify";
import NotFound from "./pages/NotFound";

var ParentLayout = styled.section``;

function App() {
  return (
    <ParentLayout>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/terms-and-conditions" element={<TermsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* <Routes>
        <Route path="/terms-and-conditions" element={<TermsPage />}></Route>
      </Routes>
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPage />}></Route>
      </Routes>
      <Routes>
        <Route path="*" element={<NotFound />}></Route>
      </Routes> */}
      {/* <HomePage /> */}
    </ParentLayout>
  );
}

export const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", Bold',
  },
  overrides: {
    CssBaseline: {
      "@global": {
        "@font-face": [MontserratBold],
      },
    },
  },
});

export default App;
