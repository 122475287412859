import { useEffect, useState } from "react";
import styled from "styled-components";
import GallerySection from "../components/GallerySection";
import Image2 from "../images/alyonaicon.jpeg";
import Rupee from "../images/rupee.png";
import QuickIcon from "../images/quick.png";
import AssuredIcon from "../images/assured.png";
import PaperlessIcon from "../images/paperless.png";
import NbfcIcon from "../images/nbfc.png";
import VerifiedIcon from "../images/verified.png";
import CommunityIcon from "../images/community.png";
import TrustIcon from "../images/trust.png";
import MissionIcon from "../images/mission.png";
import InterestIcon from "../images/interest.png";
import DoubtIcon from "../images/doubt.png";
import LoveIcon from "../images/love.png";
import HeadingElement from "../components/HeadingElement";
import ContentElement from "../components/ContentElement";
import staticants from "../utils/Constants";
import ServiceTile from "./ServiceTile";

const HomeServicesLayout = styled.section``;

function ServiceSection() {
  return (
    <HomeServicesLayout>
      <ServiceTile
        icon={NbfcIcon}
        mt="We Are Trusted NBFC"
        st={staticants.serviceText1}
      />
      <ServiceTile
        icon={VerifiedIcon}
        mt="We Are Governement Verified"
        st={staticants.serviceText1}
      />
      <ServiceTile
        icon={CommunityIcon}
        mt="1000+ Happy Customers"
        st={staticants.serviceText1}
      />
      <ServiceTile
        icon={TrustIcon}
        mt="We Believe In Good Work"
        st={staticants.serviceText1}
      />
      <ServiceTile
        icon={MissionIcon}
        mt="Easy Loan Our Mission"
        st={staticants.serviceText1}
      />
      <ServiceTile
        icon={InterestIcon}
        mt="Lowest Interest Rates"
        st={staticants.serviceText1}
      />
    </HomeServicesLayout>
  );
}

export default ServiceSection;
