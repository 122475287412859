import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import AlyonaIcon from "../images/alyonatrans.png";
import HomeIcon from "@mui/icons-material/Home";
import CollectionsIcon from "@mui/icons-material/Collections";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import RateReviewIcon from "@mui/icons-material/RateReview";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import {
  Box,
  Divider,
  List,
  Typography,
  Drawer,
  IconButton,
} from "@mui/material";
import MenuItem from "./MenuItem";
import { Link } from "react-router-dom";

const Wrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0px;
  height: 50px;
  z-index: 10;
  padding: 0em;
  border-radius: 0px;
  border: 1px solid lightgray;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background-color: white;
  /* align-items: center;
  justify-content: space-between; */
`;

const MenuSection = styled.section`
  margin: 10px;
`;
const HeadingSection = styled.section``;

const MenuHeaderLayout = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

function NavMenu(props) {
  var width = window.innerWidth;
  const [open, setOpen] = useState(false);
  const [device, setDevice] = useState("Uncertain");
  const [openupgradeModel, setOpenupgradeModel] = React.useState(false);

  const getDeviceType = () => {
    var width = window.innerWidth;

    if (width <= 768) {
      setDevice("Mobile");
    } else if (width >= 768 && width <= 1024) {
      setDevice("Tablet");
    } else if (width >= 1024) {
      setDevice("Desktop");
    } else {
      setDevice("Uncertain");
    }
  };

  useEffect(() => {
    getDeviceType();
  }, [width]);

  const toggleDrawer = () => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(false);
  };

  const drawerItemList = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 300,
        color: "black",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box>
        <List>
          <MenuHeaderLayout>
            <img width="100px" height="100px" src={AlyonaIcon} alt="" />
            <Typography
              sx={{ fontSize: "16px", fontWeight: "700", color: "black" }}
            >
              © AlyonaFinance® NBFC
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                color: "black",
                textAlign: "center",
                marginTop: "5px",
                marginBottom: "10px",
              }}
            >
              An ISO 9001:2015 Certified
            </Typography>
          </MenuHeaderLayout>
          <Divider color="white" />
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/#hash-home"
          >
            <MenuItem Icon={HomeIcon} heading="Home" />
          </Link>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/#hash-gallery"
          >
            <MenuItem Icon={CollectionsIcon} heading="Gallery" />
          </Link>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/#hash-services"
          >
            <MenuItem Icon={FeaturedPlayListIcon} heading="Services" />
          </Link>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/#hash-chooseus"
          >
            <MenuItem Icon={AssuredWorkloadIcon} heading="Why Choose Us" />
          </Link>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/#hash-contactus"
          >
            <MenuItem Icon={AddIcCallIcon} heading="Contact Us" />
          </Link>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/#hash-location"
          >
            <MenuItem Icon={LocationOnIcon} heading="Our Location" />
          </Link>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/#hash-cities"
          >
            <MenuItem Icon={LocationCityIcon} heading="Cities We Operate" />
          </Link>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/#hash-reviews"
          >
            <MenuItem Icon={RateReviewIcon} heading="Customer Reviews" />
          </Link>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/#hash-download-app"
          >
            <MenuItem Icon={AppShortcutIcon} heading="Download App" />
          </Link>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/privacy-policy"
          >
            <MenuItem Icon={PrivacyTipIcon} heading="Privacy Policy" />
          </Link>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/terms-and-conditions"
          >
            <MenuItem Icon={ErrorOutlineIcon} heading="Terms & Conditions" />
          </Link>
        </List>
      </Box>
    </Box>
  );

  const drawer = (
    <React.Fragment>
      <Drawer
        variant={
          device === "Desktop"
            ? "permanent"
            : device === "Uncertain"
            ? "temporary"
            : "temporary"
        }
        //variant={matches ? "temporary" : "permanent"}
        anchor={"left"}
        color="black"
        open={open}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
        PaperProps={{
          sx: {
            backgroundColor: "#202123",
            width: 320,
          },
        }}
      >
        {drawerItemList("left")}
      </Drawer>
    </React.Fragment>
  );

  return (
    <div>
      {drawer}
      <Wrapper>
        <MenuSection>
          <IconButton onClick={() => setOpen(true)}>
            <MenuIcon
              fontSize="small"
              sx={{
                width: "32px",
                height: "32px",
                color: "black",
              }}
            />
          </IconButton>
        </MenuSection>
        <HeadingSection>
          <Typography
            sx={{
              color: "black",
              fontWeight: "500",
              fontSize: "15px",
            }}
          >
            Alyona Finance
          </Typography>
        </HeadingSection>
        {/* <NewChatSection>
          <AddIcon
            fontSize="large"
            sx={{
              color: "#C5C5CF",
            }}
          />
        </NewChatSection> */}
      </Wrapper>
    </div>
  );
}

export default NavMenu;
