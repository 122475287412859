import { useEffect, useState } from "react";
import styled from "styled-components";
import GallerySection from "../components/GallerySection";
import { Avatar, Card, Typography } from "@mui/material";
import HeadingElement from "./HeadingElement";
import ContentElement from "./ContentElement";
import staticants from "../utils/Constants";

const ReviewParentLayout = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
`;

function SingleReview(props) {
  return (
    <div>
      <Card
        elevation={10}
        sx={{ height: "280px", marginRight: "15px", marginBottom: "15px" }}
      >
        <ReviewParentLayout>
          <Avatar
            sx={{ marginBottom: "7px", width: "90px", height: "90px" }}
            src={props.img}
          />
          <HeadingElement heading={props.name} size="17px" weight="450" />
          <Typography
            sx={{
              fontSize: "12px",
              color: "grey",
              textAlign: "center",
              maxLines: "3",
              overflow: "hidden",
              
              marginTop: "5px",
            }}
          >
            {props.review}
          </Typography>
        </ReviewParentLayout>
      </Card>
    </div>
  );
}

export default SingleReview;
