import { useEffect, useState } from "react";
import styled from "styled-components";
import GallerySection from "../components/GallerySection";
import { Avatar, Card, Typography } from "@mui/material";
import HeadingElement from "./HeadingElement";
import ContentElement from "./ContentElement";
import staticants from "../utils/Constants";
import Person1Photo from "../images/person1.jpg";
import Person2Photo from "../images/person2.jpg";
import Person3Photo from "../images/person3.jpg";
import Person4Photo from "../images/person4.jpg";
import Person5Photo from "../images/person5.jpg";
import Person6Photo from "../images/person6.jpg";
import Person7Photo from "../images/person7.jpg";
import Person8Photo from "../images/person8.jpg";
import Slider from "react-slick";
import SingleReview from "./SingleReview";

const ReviewParentLayout = styled.section`
`;

const GallerySettings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 50,
  accessibility: false,
  arrows: false,
};

function ReviewsSection() {
  return (
    <ReviewParentLayout>
      <Slider {...GallerySettings}>
        <div>
          <SingleReview
            img={Person1Photo}
            name="Rakesh Maurya"
            review={staticants.review1}
          />
        </div>

        <div>
          <SingleReview
            img={Person2Photo}
            name="Ramadhar Yadav"
            review={staticants.review2}
          />
        </div>
        <div>
          <SingleReview
            img={Person3Photo}
            name="Phatima Ali Khan"
            review={staticants.review3}
          />
        </div>
        <div>
          <SingleReview
            img={Person4Photo}
            name="Sukhdeo Mahto"
            review={staticants.review1}
          />
        </div>

        <div>
          <SingleReview
            img={Person5Photo}
            name="Kratntivir Yadav"
            review={staticants.review2}
          />
        </div>

        <div>
          <SingleReview
            img={Person6Photo}
            name="Shamshul Warsi"
            review={staticants.review3}
          />
        </div>

        <div>
          <SingleReview
            img={Person7Photo}
            name="Ahemad Nazir"
            review={staticants.review1}
          />
        </div>

        <div>
          <SingleReview
            img={Person8Photo}
            name="Manish Sharma"
            review={staticants.review3}
          />
        </div>
      </Slider>
    </ReviewParentLayout>
  );
}

export default ReviewsSection;
