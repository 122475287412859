import { useEffect, useState } from "react";
import styled from "styled-components";
import GallerySection from "../components/GallerySection";
import Image2 from "../images/alyonaicon.jpeg";
import Rupee from "../images/rupee.png";
import QuickIcon from "../images/quick.png";
import AssuredIcon from "../images/assured.png";
import PaperlessIcon from "../images/paperless.png";
import NbfcIcon from "../images/nbfc.png";
import VerifiedIcon from "../images/verified.png";
import CommunityIcon from "../images/community.png";
import TrustIcon from "../images/trust.png";
import MissionIcon from "../images/mission.png";
import InterestIcon from "../images/interest.png";
import DoubtIcon from "../images/doubt.png";
import LoveIcon from "../images/love.png";
import HeadingElement from "../components/HeadingElement";
import ContentElement from "../components/ContentElement";
import staticants from "../utils/Constants";
import ServiceTile from "./ServiceTile";

const ChooseLayout = styled.section`
`;

function ChooseusSection() {
  return (
    <ChooseLayout>
      <ServiceTile
        icon={QuickIcon}
        mt="Quick Loan Process"
        st={staticants.serviceText1}
      />
      <ServiceTile
        icon={AssuredIcon}
        mt="Gurranted Loan Approval"
        st={staticants.serviceText1}
      />
      <ServiceTile
        icon={PaperlessIcon}
        mt="Complete Paperless Process"
        st={staticants.serviceText1}
      />
      <ServiceTile
        icon={Rupee}
        mt="Secure Loan Transfer"
        st={staticants.serviceText1}
      />
      <ServiceTile
        icon={QuickIcon}
        mt="Quick Loan Process"
        st={staticants.serviceText1}
      />
    </ChooseLayout>
  );
}

export default ChooseusSection;
